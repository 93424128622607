<template>
    <div style="padding:15px;">
        <div style="text-align: center;">
            <el-radio-group v-model="logtype">
                <el-radio-button label="LogVis">访问日志</el-radio-button>
                <el-radio-button label="LogAudit">审核日志</el-radio-button>
                <el-radio-button label="LogOp">操作日志</el-radio-button>
                <el-radio-button label="LogEx">错误日志</el-radio-button>
            </el-radio-group>
        </div>
        <el-divider></el-divider>
        <div>
            <el-form :inline="true">
                <el-input v-model="keyword" placeholder="请输入关键字查询" class="input-with-select" style="width:220px;margin-right:10px;">

                </el-input>
                <el-form-item label="">
                    <el-date-picker v-model="searchStartTime" type="date" placeholder="开始日期"></el-date-picker>
                    <el-date-picker v-model="searchEndTime" type="date" placeholder="结束日期"></el-date-picker>
                </el-form-item>
                <slot name="searchSlot"></slot>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" size="medium" @click="SearchPage">查询</el-button>
                    <!-- <el-button type="warning" icon="el-icon-share" size="medium" @click="ExportExcel">导出</el-button> -->
                </el-form-item>
            </el-form>
        </div>
        <el-divider></el-divider>
        <div>
            <el-table :fit="true" :data="tableData" style="width: 100%" max-height="600">
                <el-table-column v-for="(item) in tableCol" :key="item" :prop="item.prop" :label="item.label" />
                <!-- <el-table-column prop="date" label="Date" />
                <el-table-column prop="name" label="Name" />
                <el-table-column prop="address" label="Address" /> -->
            </el-table>
            <div style="height:60px;position:fixed;bottom:0;width:100%;">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :default-page-size="100" :page-sizes="[100, 200, 500, 1000]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>

    </div>
</template>
<script>
import config from "@/config";
import axios from "@/utils/request";
export default {
    name: "log",
    data() {
        return {
            tableData: [
                {
                    date: "2016-05-03",
                    name: "Tom",
                    address: "No. 189, Grove St, Los Angeles",
                },
                {
                    date: "2016-05-02",
                    name: "Tom",
                    address: "No. 189, Grove St, Los Angeles",
                },
                {
                    date: "2016-05-04",
                    name: "Tom",
                    address: "No. 189, Grove St, Los Angeles",
                },
                {
                    date: "2016-05-01",
                    name: "Tom",
                    address: "No. 189, Grove St, Los Angeles",
                },
            ],
            tableCol: [], //列信息
            logtype: "LogVis", //日志类型
            keyword: "", //搜索关键字
            searchStartTime: "", //开始时间
            searchEndTime: "", //结束时间
            pageSize: 100, //分页数量
            pageNum: 1, //分页下标
            total: 0, //总页数量
            pageInfo: [
                {
                    logType: "LogVis",
                    dataTable: [],
                    dataCol: [
                        { prop: "Id", label: "Id" },
                        { prop: "Name", label: "名称" },
                        { prop: "Success", label: "执行结果" },
                        { prop: "Message", label: "具体消息" },
                        { prop: "Ip", label: "Ip" },
                        { prop: "Location", label: "地址" },
                        { prop: "Browser", label: "浏览器" },
                        { prop: "Os", label: "操作系统" },
                        { prop: "Account", label: "访问人" },
                        { prop: "VisType", label: "访问类型" },
                        { prop: "VisTime", label: "访问时间" },
                    ],
                },
                {
                    logType: "LogAudit",
                    dataTable: [],
                    dataCol: [
                        { prop: "Id", label: "Id" },
                        { prop: "TableName", label: "表名" },
                        { prop: "ColumnName", label: "列名" },
                        { prop: "OldValue", label: "旧值" },
                        { prop: "NewValue", label: "新值" },
                        { prop: "CreatedTime", label: "操作时间" },
                        { prop: "UserName", label: "操作人" },
                    ],
                }
                ,
                {
                    logType: "LogEx",
                    dataTable: [],
                    dataCol: [
                        { prop: "Id", label: "Id" },
                        { prop: "ClassName", label: "类名" },
                        { prop: "MethodName", label: "方法名" },
                        { prop: "ExceptionName", label: "异常名称" },
                        { prop: "ExceptionMsg", label: "异常信息" },
                        { prop: "ExceptionSource", label: "异常源" },
                        { prop: "ExceptionTime", label: "异常时间" },
                        { prop: "Account", label: "操作人" },
                    ],
                }
                ,
                {
                    logType: "LogOp",
                    dataTable: [],
                    dataCol: [
                        { prop: "Id", label: "Id" },
                        { prop: "LogName", label: "名称" },
                        { prop: "Success", label: "执行结果" },
                        { prop: "Message", label: "具体消息" },
                        { prop: "Ip", label: "Ip" },
                        { prop: "Location", label: "地址" },
                        { prop: "Browser", label: "浏览器" },
                        { prop: "Os", label: "操作系统" },
                        { prop: "Url", label: "请求地址" },
                        { prop: "ClassName", label: "类名称" },
                        { prop: "MethodName", label: "方法名称" },
                        { prop: "ReqMethod", label: "请求方式" },
                        //{ prop: "Param", label: "请求参数" },
                        //{ prop: "Result", label: "返回结果" },
                        { prop: "ElapsedTime", label: "耗时" },
                        { prop: "Account", label: "操作人" },
                        { prop: "OpTime", label: "操作时间" },
                    ],
                },
            ],
        };
    },
    mounted() {
        var that = this;
        that.pageInfo.forEach((element) => {
            if (element.logType == that.logtype) {
                that.tableCol = element.dataCol;
                that.SearchPage();
            }
        });
    },
    watch: {
        logtype(val) {
            var that = this;
            that.pageInfo.forEach((element) => {
                if (element.logType == val) {
                    that.tableCol = element.dataCol;
                    that.SearchPage();
                }
            });
        },
    },
    methods: {
        SearchPage() {
            var json = {
                LogType: this.logtype,
                KeyWorld: this.keyword,
                StartTime: this.searchStartTime,
                EndTime: this.searchEndTime,
                PageSize: this.pageSize,
                PageNum: this.pageNum,
            };
            axios
                .post(`${config.API_URL}/Log/QueryByPage`, json)
                .then((res) => {
                    console.log("SearchPage", res);
                    this.total = res.TotalCount;
                    this.tableData = res.Items;
                });
        },
        handleSizeChange(val) {
            console.log(this.val);
            this.pageSize = val;
            this.SearchPage();
        },
        handleCurrentChange(val) {
            console.log(this.val);
            this.pageNum = val;
            this.SearchPage();
        },
    },
};
</script>
<style>
.sx {
    text-align: center;
}
</style>